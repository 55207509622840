import { render, staticRenderFns } from "./CommonHeader.vue?vue&type=template&id=aef7070c&scoped=true"
import script from "./CommonHeader.vue?vue&type=script&lang=js"
export * from "./CommonHeader.vue?vue&type=script&lang=js"
import style0 from "./CommonHeader.vue?vue&type=style&index=0&id=aef7070c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef7070c",
  null
  
)

export default component.exports